import { testOrgWebhookRequest } from 'api/Orgs/api';
import getErrorFromPromiseReason from 'app/helpers/getErrorFromPromiseReason';

import { message, notification } from 'antd';
import { useCallback, useState } from 'react';

const useOrgWebhooksTest = () => {
  const [ isLoading, setIsLoading ] = useState<boolean>(false);

  const testWebhook = useCallback((orgId: string, webhookId: string) => {
    setIsLoading(true);
    testOrgWebhookRequest(orgId, webhookId)
      .then(() => {
        message.success('Webhook successfully sent');
      })
      .catch((reason) => {
        notification.error({ duration: 0, type: 'error', message: getErrorFromPromiseReason(reason) });
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, []);

  return { isLoading, testWebhook };
};

export default useOrgWebhooksTest;
