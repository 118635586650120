import {
  setAccessToken,
  setRefreshToken,
  setWebsocketAccessToken,
  removeAccessToken,
  removeRefreshToken,
  removeWebsocketAccessToken,
  getRefreshToken,
} from 'app/helpers/auth';
import { postAuthRequest, postLogoutRequest } from 'api/Auth/api';
import { AuthResponse, AuthParams, CommonAuthResponse, MFAAuthResponse } from 'api/Auth/types';
import getErrorFromPromiseReason from 'app/helpers/getErrorFromPromiseReason';
import { AuthState } from 'store/auth/types';
import { RootState } from 'store/types';

import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';

export const initialState: AuthState = {
  mfaSessionId: null,
  error: null,
  isAuthenticated: false,
  status: 'loading',
  websocketAccessToken: null,
  selfLogout: undefined,
};

export const authenticateAsync = createAsyncThunk(
  'auth/authenticate',
  async ({ email, password }: AuthParams, { rejectWithValue }) => {
    try {
      const response = await postAuthRequest(email, password);
      return response.data;
    } catch (err) {
      const message = getErrorFromPromiseReason(err);
      return rejectWithValue(message);
    }
  },
);

export const logoutAsync = createAsyncThunk(
  'auth/blacklistToken',
  async (_, { dispatch }) => {
    const refresh = getRefreshToken();
    postLogoutRequest(refresh!).finally(() => dispatch(logout({ selfLogout: true })));
  },
);

export const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    logout: (state, action: PayloadAction<{ selfLogout?: boolean }>) => {
      removeAccessToken();
      removeRefreshToken();
      removeWebsocketAccessToken();
      state.status = 'idle';
      state.isAuthenticated = false;
      state.mfaSessionId = null;
      state.error = null;
      state.websocketAccessToken = null;
      state.selfLogout = action.payload.selfLogout;
    },
    login: (state, action: PayloadAction<CommonAuthResponse>) => {
      const { payload: { access, refresh, websocketAccess } } = action;
      setAccessToken(access);
      setRefreshToken(refresh);
      setWebsocketAccessToken(websocketAccess);
      state.status = 'idle';
      state.isAuthenticated = true;
      state.mfaSessionId = null;
      state.error = null;
      state.websocketAccessToken = websocketAccess;
      state.selfLogout = undefined;
    },
    resetError: (state) => {
      state.error = null;
    },
    setMfaSessionId: (state, action: PayloadAction<string>) => {
      state.mfaSessionId = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(authenticateAsync.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(authenticateAsync.fulfilled, (state, action: PayloadAction<AuthResponse>) => {
        if ('mfaSessionId' in action.payload) {
          const { mfaSessionId } = action.payload as MFAAuthResponse;
          state.mfaSessionId = mfaSessionId;
        } else {
          const { access, refresh, websocketAccess } = action.payload as CommonAuthResponse;
          setAccessToken(access);
          setRefreshToken(refresh);
          setWebsocketAccessToken(websocketAccess);
          state.websocketAccessToken = websocketAccess;
          state.isAuthenticated = true;
        }
        state.error = null;
        state.status = 'idle';
      })
      .addCase(authenticateAsync.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.payload as string;
      });
  },
});

export const {
  logout,
  login,
  resetError,
  setMfaSessionId,
} = authSlice.actions;

export const selectIsAuthenticated = (state: RootState) => state.auth.isAuthenticated;
export const selectAuthStatus = (state: RootState) => state.auth.status;
export const selectMFASessionId = (state: RootState) => state.auth.mfaSessionId;
export const selectAuthError = (state: RootState) => state.auth.error;
export const selectWebsocketAccessToken = (state: RootState) => state.auth.websocketAccessToken;
export const selectSelfLogout = (state: RootState) => state.auth.selfLogout;

export default authSlice.reducer;
