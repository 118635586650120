import { File } from 'api/Data/types';
import { FileOption } from 'components/Dashboard/Orders/NewOrderForm/types';

export const setFileOptionValue = (file: File): string => `${file.id}_${file.genomeType}`;

export const getFileIdFromFileOption = (option: FileOption): string => option.value.split('_').shift()!;

export const isFileExist = (files?: FileOption[]): boolean => !!files && files.length > 0;

export const getAmountOfSampleFromFileOption = (option: FileOption): string => option.label.split(' | ').shift()!;
