export enum SearchParams {
  PAGE = 'page',
  PAGE_SIZE = 'pageSize',
  SEARCH = 'search',
  ORG = 'org',
  ORDER_STATUS = 'orderStatus',
  ORDER_GROUP_STATUS = 'orderGroupStatus',
  TAG = 'tag',
  MODEL = 'model',
  MODEL_API = 'modelApi',
  MIN_DATE = 'minDate',
  MAX_DATE = 'maxDate',
  CREDIT_SERVICE_TYPE = 'creditServiceType',
  FULL_LEDGER = 'fullLedger',
  TYPE = 'type',
  PLOT_REQUEST_ID = 'plotRequestId',
  ORDERS_IDS = 'orders_ids', // this is used only with direct urls
  FILES_IDS = 'files_ids', // this is used only with direct urls
}
