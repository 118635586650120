import { File } from 'api/Data/types';

export const getFileStatusColor = (status: File['status']) => {
  switch (status) {
    case 'VALID':
      return 'purple';
    case 'PENDING':
      return 'geekblue';
    case 'FAILED':
      return 'red';
    case 'NOT_VALID':
      return 'red';
    default:
      return 'default';
  }
};

export const titleCaseWord = (str: string) => {
  return str[0].toUpperCase() + str.slice(1).toLowerCase();
};
