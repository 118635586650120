import { Webhook } from 'api/Orgs/types';
import { useOrgWebhooksTest } from 'components/Dashboard/Orgs/Wizard/Webhooks/hooks';

import { Divider, Space, Typography } from 'antd';
import React from 'react';

interface CardExtraProps {
  orgId: string;
  webhooks: Webhook[];
  name: number;
  remove: (index: number) => void;
}

const WebhookCardExtra: React.FC<CardExtraProps> = ({ orgId, webhooks, remove, name }) => {
  const { isLoading: isWebhookTesting, testWebhook } = useOrgWebhooksTest();

  const testDisabled = !webhooks[name] || isWebhookTesting;

  const onTestClick = (webhookId: string | null) => {
    if (webhookId) {
      testWebhook(orgId, webhookId);
    }
  };

  return (
    <Space size="small" split={<Divider type="vertical" />}>

      <Typography.Link
        disabled={testDisabled}
        onClick={() => onTestClick(webhooks[name].id)}
      >
        Test webhook
      </Typography.Link>

      <Typography.Link onClick={() => remove(name)}>
        Remove
      </Typography.Link>
    </Space>
  );
};

export default WebhookCardExtra;
