import { dashboardUrl, homeUrl, ordersUrl } from 'app/consts/urls';
import useAuth from 'app/hooks/useAuth';
import useMyUser from 'app/hooks/useMyUser';
import AppLoader from 'app/common_components/AppLoader/AppLoader';
import AuthContainer from 'containers/AuthContainer/AuthContainer';
import DashboardContainer from 'containers/DashboardContainer/DashboardContainer';
import { useAppSelector } from 'store/hooks';
import { selectSelfLogout } from 'store/auth/authSlice';
import { selectUsersStatus, selectMyUser } from 'store/users/usersSlice';

import React from 'react';
import { Route, Routes, Navigate, useLocation } from 'react-router-dom';

const App: React.FC = () => {
  useAuth();
  useMyUser();

  const myUserStatus = useAppSelector(selectUsersStatus);
  const { id } = useAppSelector(selectMyUser);
  const selfLogout = useAppSelector(selectSelfLogout);

  const location = useLocation();
  const from: string = !selfLogout && location.state ? `${location.state.pathname}${location.state.search}` : `/${dashboardUrl}/${ordersUrl}`;

  return myUserStatus === 'loading' ? <AppLoader /> : (
    <Routes>
      <Route path={`/${dashboardUrl}/*`} element={id ? <DashboardContainer /> : <Navigate to={homeUrl} state={location} replace />} />
      <Route path="/*" element={!id ? <AuthContainer /> : <Navigate to={from} state={location} replace />} />
    </Routes>
  );
};

export default App;
