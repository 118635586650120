import { getFilesRequest } from 'api/Data/api';
import { FilesFilterParams } from 'api/Data/types';
import { FileOption } from 'components/Dashboard/Orders/NewOrderForm/types';
import { setFileOptionValue } from 'components/Dashboard/Orders/NewOrderForm/helpers';
import Dropdown from 'components/Dashboard/Orders/NewOrderForm/SearchFilesSelect/Dropdown/Dropdown';

import React, { useMemo, useRef, useState, useCallback } from 'react';
import { Select, Spin } from 'antd';
import type { SelectProps } from 'antd/es/select';
import debounce from 'lodash/debounce';

export interface Props extends Omit<SelectProps<FileOption[]>, 'options' | 'children'> {
  debounceTimeout?: number;
}

const SearchFilesSelect: React.FC<Props> = ({ debounceTimeout = 800, ...props }) => {
  const [ fetching, setFetching ] = useState(false);
  const [ searchValue, setSearchValue ] = useState<string>('');
  const [ optionsCount, setOptionsCount ] = useState<number>(0);
  const [ options, setOptions ] = useState<FileOption[]>([]);
  const fetchRef = useRef(0);

  const renderDropdown = useCallback((menu: React.ReactElement) => {
    return <Dropdown menu={menu} fileName={searchValue} count={optionsCount} />;
  }, [ searchValue, optionsCount ]);

  const searchFiles = (file: string): Promise<FileOption[]> => {
    if (file.length <= 3) {
      return Promise.resolve([]);
    }

    const filter: FilesFilterParams = {
      file,
      onlyAcceptable: true,
      orgs: [],
      minDate: '',
      maxDate: '',
      showVirtual: false,
      filesIds: '',
    };

    return getFilesRequest(1, 10, filter).then((response): FileOption[] => {
      const results: FileOption[] = response.data.results.map(file => ({
        label: `${file.amountOfSamples} | ${file.srcShortFilePath}`,
        value: setFileOptionValue(file),
      }));
      if (results.length > 0) {
        results.unshift({ label: 'Samples | File path', value: '', disabled: true });
      }

      return results;
    });
  };

  const debounceFetcher = useMemo(() => {
    const loadOptions = (value: string) => {
      fetchRef.current += 1;
      const fetchId = fetchRef.current;
      setOptions([]);
      setFetching(true);

      searchFiles(value).then((newOptions) => {
        if (fetchId !== fetchRef.current) {
          // for fetch callback order
          return;
        }

        setSearchValue(value);
        setOptionsCount(newOptions.length);
        setOptions(newOptions);
        setFetching(false);
      });
    };

    return debounce(loadOptions, debounceTimeout);
  }, [ debounceTimeout ]);

  return (
    <Select
      mode="multiple"
      labelInValue
      filterOption={false}
      onSearch={debounceFetcher}
      notFoundContent={fetching ? <Spin size="small" /> : null}
      {...props}
      options={options}
      dropdownRender={renderDropdown}
    />
  );
};

export default SearchFilesSelect;
