import { Order } from 'api/Exec/types';
import PdfFormData from 'app/common_components/PdfFormData/PdfFormData';
import { PrsPdfMetadata } from 'app/common_components/PdfFormData/types';
import TagsSelect from 'app/common_components/TagsSelect/TagsSelect';
import useCreateOrder from 'app/hooks/useCreateOrder';
import useOrgModelsOptions from 'app/hooks/useOrgModelsOptions';
import { RerunFile } from 'components/Dashboard/Orders/Orders';

import { Button, Form, Select, Space } from 'antd';
import React from 'react';

interface FormValues {
  modelName: string;
  tagIds: string[];
  pdfData?: PrsPdfMetadata;
}

interface Props {
  file: RerunFile;
  onCancel: () => void;
  onOrderCreated: (order: Order) => void;
}

const CreateAncestryCallForm: React.FC<Props> = ({ file, onCancel, onOrderCreated }) => {
  const [ form ] = Form.useForm<FormValues>();
  const modelName = Form.useWatch('modelName', form);

  const { createOrder, isLoading } = useCreateOrder(onOrderCreated);

  const { orgModelsOptions } = useOrgModelsOptions();

  const onFinish = (values: FormValues) => {
    createOrder(file.id, values.modelName, values.tagIds, values.pdfData);
  };

  return (
    <Form
      form={form}
      name="createAncestryCallForm"
      layout="vertical"
      requiredMark={false}
      onFinish={onFinish}
      style={{ marginTop: 24 }}
      initialValues={{ tagIds: file.tagIds || [] }}
    >
      <Form.Item
        name="modelName"
        label="Model"
        rules={[ { required: true, message: 'Field is mandatory' } ]}
      >
        <Select placeholder="Select model" disabled={isLoading} options={orgModelsOptions} />
      </Form.Item>

      {file.amountOfSamples === 1 && <PdfFormData isLoading={isLoading} modelName={modelName} />}

      <Form.Item name="tagIds" label="Tags">
        <TagsSelect disabled={isLoading} />
      </Form.Item>

      <Form.Item style={{ textAlign: 'right', marginBottom: 0 }}>
        <Space>
          <Button onClick={onCancel}>
            Cancel
          </Button>
          <Button type="primary" htmlType="submit" disabled={isLoading}>
            Submit
          </Button>
        </Space>
      </Form.Item>
    </Form>
  );
};

export default CreateAncestryCallForm;
