import PdfFormData from 'app/common_components/PdfFormData/PdfFormData';
import TagsSelect from 'app/common_components/TagsSelect/TagsSelect';
import useCreateOrder from 'app/hooks/useCreateOrder';
import useOrgModelsOptions from 'app/hooks/useOrgModelsOptions';
import { getAmountOfSampleFromFileOption, getFileIdFromFileOption, isFileExist } from 'components/Dashboard/Orders/NewOrderForm/helpers';
import { FileOption, FormValues, Props } from 'components/Dashboard/Orders/NewOrderForm/types';
import SearchFilesSelect from 'components/Dashboard/Orders/NewOrderForm/SearchFilesSelect/SearchFilesSelect';

import { Button, Form, Select, Space } from 'antd';
import React, { useMemo, useState } from 'react';

const NewOrderForm: React.FC<Props> = ({ onCancel, onOrderCreated }) => {
  const [ open, setOpen ] = useState<boolean>(false);
  const [ form ] = Form.useForm<FormValues>();
  const files = Form.useWatch('files', form);
  const modelName = Form.useWatch('modelName', form);

  const { orgModelsOptions } = useOrgModelsOptions();
  const { createOrder, isLoading } = useCreateOrder(onOrderCreated);

  const onFinish = (values: FormValues) => {
    const fileId = getFileIdFromFileOption(values.files[0]);
    createOrder(fileId, values.modelName, values.tagIds, values.pdfData);
  };

  const handleChange = (value: FileOption[]) => {
    const currentValue = value.at(value.length - 1);
    if (currentValue) {
      form.setFieldValue('files', [ currentValue ]);
    } else {
      form.setFieldValue('files', []);
      form.setFieldValue('modelName', undefined);
      form.setFieldValue('tagIds', []);
    }
    setOpen(false);
  };

  const areOtherFieldsDisabled = !isFileExist(files);

  const amountOfSamples = useMemo(() => {
    return isFileExist(files) ? Number(getAmountOfSampleFromFileOption(files[0])) : 0;
  }, [ files ]);

  return (
    <Form
      form={form}
      name="newOrderForm"
      layout="vertical"
      requiredMark={false}
      onFinish={onFinish}
      style={{ marginTop: 24 }}
      initialValues={{ tagIds: [] }}
    >
      <Form.Item
        name="files"
        label="File name"
        rules={[ { required: true, message: 'Field is mandatory' } ]}
      >
        <SearchFilesSelect
          open={open}
          onDropdownVisibleChange={(visible: boolean) => setOpen(visible)}
          placeholder="Search file by name"
          debounceTimeout={400}
          onChange={handleChange}
          disabled={isLoading}
          popupMatchSelectWidth={false}
        />
      </Form.Item>

      <Form.Item
        name="modelName"
        label="Model"
        tooltip={areOtherFieldsDisabled && 'Please select a file first'}
        rules={[ { required: true, message: 'Field is mandatory' } ]}
      >
        <Select
          placeholder="Select model"
          options={orgModelsOptions}
          disabled={isLoading || areOtherFieldsDisabled}
        />
      </Form.Item>

      {amountOfSamples === 1 && <PdfFormData isLoading={isLoading} modelName={modelName} />}

      <Form.Item
        name="tagIds"
        label="Tags"
        tooltip={areOtherFieldsDisabled && 'Please select a file first'}
      >
        <TagsSelect disabled={isLoading || areOtherFieldsDisabled} />
      </Form.Item>

      <Form.Item style={{ textAlign: 'right', marginBottom: 0 }}>
        <Space>
          <Button onClick={onCancel}>
            Cancel
          </Button>
          <Button type="primary" htmlType="submit" disabled={isLoading}>
            Submit
          </Button>
        </Space>
      </Form.Item>
    </Form>
  );
};

export default NewOrderForm;
