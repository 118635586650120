import { getAccessToken, getRefreshToken } from 'app/helpers/auth';
import { axiosInstance } from 'api/instance';
import { postRefreshTokenRequest } from 'api/Auth/api';
import urls from 'api/Auth/urls';
import { logout, login } from 'store/auth/authSlice';

import { AxiosRequestHeaders } from 'axios';
import { Store } from '@reduxjs/toolkit';
import { camelizeKeys, decamelizeKeys } from 'humps';

const setup = (store: Store) => {
  axiosInstance.interceptors.request.use(
    (config) => {
      const headers = { ...config.headers } as AxiosRequestHeaders;
      const { data } = config;
      const token = getAccessToken();
      if (token) {
        headers.Authorization = `Bearer ${token}`;
      }

      return {
        ...config,
        data: data instanceof FormData ? data : decamelizeKeys(data),
        headers,
      };
    },
    err => Promise.reject(err),
  );

  const { dispatch } = store;

  axiosInstance.interceptors.response.use(
    response => ({
      ...response,
      data: response.headers['content-type'] === 'application/octet-stream' ? response.data : camelizeKeys(response.data),
    }),
    async (err) => {
      const { config, response } = err;

      // Access Token was expired
      if (
        response && response.status === 401 &&
        !config._retry &&
        config.url !== urls.refreshUrl &&
        config.url !== urls.confirmUrl &&
        config.url !== urls.requestNewCodeUrl
      ) {
        config._retry = true;

        try {
          const storedRefreshToken = getRefreshToken();

          if (storedRefreshToken) {
            const storedAccessToken = getAccessToken() || '';
            const refreshingToken = await postRefreshTokenRequest(storedRefreshToken, storedAccessToken);
            const { data: { access, refresh, websocketAccess } } = refreshingToken;

            dispatch(login({ access, refresh, websocketAccess }));
            config.headers.Authorization = `Bearer ${access}`;
          } else {
            dispatch(logout({}));

            return await Promise.reject(err);
          }

          return await axiosInstance(config);
        } catch (err: any) {
          dispatch(logout({}));

          return Promise.reject(err);
        }
      }

      return Promise.reject(err);
    },
  );
};

export default setup;
