import { Webhook } from 'api/Orgs/types';
import { formatDateTime } from 'app/helpers/dateTime';
import { useOrgWebhooks, useWebhookActions } from 'components/Dashboard/Orgs/Wizard/Webhooks/hooks';
import WebhookCardExtra from 'components/Dashboard/Orgs/Wizard/Webhooks/WebhookCardExtra/WebhookCardExtra';

import { PlusOutlined } from '@ant-design/icons';
import { Button, Card, Checkbox, Form, Input, InputNumber, Select, Space } from 'antd';
import React, { useEffect } from 'react';

interface Props {
  orgId: string;
}

interface FormValues {
  webhooks: Webhook[];
}

const OrgWebhooks: React.FC<Props> = ({ orgId }) => {
  const [ form ] = Form.useForm<FormValues>();

  const { isLoading: isWebhooksLoading, fetchWebhooks, webhooks, updateWebhooks } = useOrgWebhooks();
  const { isLoading, fetchWebhookActions, webhookActions } = useWebhookActions();

  useEffect(() => {
    const abortController = new AbortController();
    fetchWebhooks(orgId, abortController);

    return () => {
      abortController.abort();
    };
  }, [ orgId, fetchWebhooks ]);

  useEffect(() => {
    const abortController = new AbortController();
    fetchWebhookActions(abortController);

    return () => {
      abortController.abort();
    };
  }, [ fetchWebhookActions ]);

  useEffect(() => {
    form.setFieldValue('webhooks', webhooks);
  }, [ form, webhooks ]);

  const onFinish = (values: FormValues) => {
    const serializedValues = values.webhooks.map(webhook => ({
      ...webhook,
      id: webhook?.id || null,
      maxRetriesCount: webhook?.maxRetriesCount || 3,
      saveErrorInNotifications: !!webhook?.saveErrorInNotifications,
    }));
    updateWebhooks(orgId, serializedValues);
  };

  return (
    <Form
      form={form}
      name="orgWebhooks"
      style={{ width: 1280 }}
      layout="vertical"
      onFinish={onFinish}
      autoComplete="off"
      requiredMark={false}
      initialValues={{ webhooks }}
      disabled={isWebhooksLoading || isLoading}
    >
      <Form.List name="webhooks">
        {(fields, { add, remove }) => (
          <Space direction="vertical" size="middle">
            {fields.map(({ key, name, ...restField }) => (
              <Card
                key={key}
                title={webhooks[name] ? `Created at ${formatDateTime(webhooks[name].createdAt)}` : ''}
                extra={<WebhookCardExtra orgId={orgId} webhooks={webhooks} remove={remove} name={name} />}
                type="inner"
              >
                <Form.Item
                  {...restField}
                  name={[ name, 'id' ]}
                  style={{ marginBottom: 0 }}
                  hidden
                >
                  <Input type="hidden" />
                </Form.Item>

                <Form.Item
                  {...restField}
                  name={[ name, 'remoteEndpoint' ]}
                  rules={[ { required: true, message: 'Enter remote endpoint' } ]}
                  style={{ width: 960, marginBottom: 16 }}
                  label="Remote endpoint"
                >
                  <Input placeholder="Enter remote endpoint" />
                </Form.Item>

                <Space size="middle" align="start">

                  <Form.Item
                    {...restField}
                    name={[ name, 'webhookAction' ]}
                    rules={[ { required: true, message: 'Select action' } ]}
                    style={{ width: 280, marginBottom: 0 }}
                    label="Action"
                  >
                    <Select placeholder="Select action" options={webhookActions} />
                  </Form.Item>

                  <Form.Item
                    {...restField}
                    name={[ name, 'maxRetriesCount' ]}
                    style={{ width: 80, marginBottom: 0 }}
                    label="Attempts"
                  >
                    <InputNumber
                      placeholder="Number"
                      style={{ width: '100%' }}
                      defaultValue={3}
                      min={1}
                      max={10}
                    />
                  </Form.Item>

                  <Form.Item
                    {...restField}
                    name={[ name, 'saveErrorInNotifications' ]}
                    valuePropName="checked"
                    style={{ marginBottom: 0, textAlign: 'center' }}
                    label="Save error"
                  >
                    <Checkbox style={{ marginLeft: -10 }} />
                  </Form.Item>

                </Space>
              </Card>
            ))}

            <Form.Item>
              <Button type="dashed" onClick={() => add()} icon={<PlusOutlined />}>
                Add webhook
              </Button>
            </Form.Item>
          </Space>
        )}
      </Form.List>

      <Form.Item style={{ width: 150 }}>
        <Button type="primary" htmlType="submit" style={{ width: '100%' }}>
          Update webhooks
        </Button>
      </Form.Item>
    </Form>
  );
};

export default OrgWebhooks;
